<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald">Coordinate System</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />

      The goal of a coordinate system is a means of specifying the position of an object.
      For example, imagine a billiards table, and consider a ball on this table.
      Now, if someone asks you to specify the location of the ball, how would you do this?<br><br>
      You could say &mdash; the ball is located in the lower half of the table.
      Or, it is near the left edge of the board.
      Or, the ball is located at the lower left corner of the table.
      None of these statements describe the position of the ball precisely. <br> <br>
      Now, if you say &mdash; the ball is 18 inches away from the left edge, it gives
      a better idea of the location of the ball but
      still does not fix the position of the ball. However, if you say the ball
      is located at 18 inches from the left edge and 12 inches from the bottom edge, it completely
      specifies the location of the ball on the table.<br><br> You may have noticed that the ball on the table can be located
      by specifying its distances from two perpendicular lines &mdash; the left edge of the table, and the bottom edge of the paper.
      This notion forms the basis of coordinate geometry.
      <br> <br>
      The position of any object lying in a plane
      can be represented by its distance from two perpendicular axes (lines). These axes are called coordinate axes, and the distances from these two axes used in specifying the location of
      the object are called its coordinates.
      <br>
      <div style="margin-top: 10px" />
      </v-layout>
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald">Learn Interactively | Coordinates of a Point on the Cartesian Plane</span>
      </div>
      <div style="margin-top: 20px" />
      <h5> Session Objectives </h5>
      <div style="margin-top: 10px" />
      This MagicGraph offers a visual+interactive illustration that helps students
      assess their understanding of coordinates of a point on the Cartesian plane.

      <div style="margin-top: 10px" />
      <h5> Getting Started </h5>
      <div style="margin-top: 10px" />
      The MagicGraph shows a point named P. Your goal is to find the x and y coordinates
      of this point.

      Enter your answers for the x and y coordinates in the blue boxes labeled as <b>x</b> and <b>y</b>, respectively.
      Once done, simply tap on the <i class="tester ma-1" /> icon to check your answer.
      To practice with a new point position, simply tap on the <i class="shuffler ma-1" /> icon to shuffle the point position.
    </div>
    <div style="margin-top: 10px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Navigation Tip: Tap on <i class="stater ma-1" /> icon see x and y coordinates of the point. Tap on <i class="eraser ma-1" /> icon to clear your response and start over.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CoordinateSystem',
  created () {
    this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Plotting a Point on Coordinate Plane';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Square Formula', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Coordinate Geometry',
          titleTemplate: '%s | Lesson 3',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
